var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"tool"},[_c('JsonPathingInput',{class:{
      input: _vm.state.maximized === 'none',
      'input-maximized': _vm.state.maximized === 'input',
      'input-result-maximized': _vm.state.maximized === 'result',
    },attrs:{"height":_vm.usableHeight,"maximized":_vm.state.maximized === 'input'},on:{"format":_vm.formatInput,"flatten":_vm.flattenInput,"normalize":_vm.normalizeInput,"maximize":_vm.maximizeInput,"transform":_vm.transformInput},model:{value:(_vm.state.input),callback:function ($$v) {_vm.$set(_vm.state, "input", $$v)},expression:"state.input"}}),_c('section',{class:{
      separator: _vm.state.maximized === 'none',
      'separator-input-maximized': _vm.state.maximized === 'input',
      'separator-result-maximized': _vm.state.maximized === 'result',
    }}),_c('JsonResultEditor',{class:{
      result: _vm.state.maximized === 'none',
      'result-input-maximized': _vm.state.maximized === 'input',
      'result-result-maximized': _vm.state.maximized === 'result',
    },attrs:{"value":_vm.state.result,"height":_vm.usableHeight,"maximized":_vm.state.maximized === 'result'},on:{"format":_vm.formatResult,"flatten":_vm.flattenResult,"normalize":_vm.normalizeResult,"maximize":_vm.maximizeResult}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }