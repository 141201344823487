




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import codemirror, { EditorFromTextArea } from 'codemirror';
import _throttle from 'lodash/throttle';

// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import foldcode from 'codemirror/addon/fold/foldcode';
// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import foldgutter from 'codemirror/addon/fold/foldgutter';
// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import bracefold from 'codemirror/addon/fold/brace-fold';
// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import closebrackets from 'codemirror/addon/edit/closebrackets';
// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import javascript from 'codemirror/mode/javascript/javascript';
// @ts-ignore (in order to get code mirror to work with webpack, typescript and babel you have to import this here)
import matchbrackets from 'codemirror/addon/edit/matchbrackets';

@Component
export default class JsonEditor extends Vue {
  @Prop({ default: false }) public editorReadOnly!: boolean;
  @Prop({ default: '{\n}' }) public value!: string;
  @Prop({ type: String, default: 'Editor' }) public editorTitle!: string;
  @Prop({ type: Number, default: 300 }) public height!: number;

  private editor: EditorFromTextArea = null;
  private throttledEditorUpdate = _throttle(this.updateEditorFromValueProp, 100);

  private updated() {
    this.throttledEditorUpdate();
  }

  private mounted() {
    const allPlugins: boolean =
      bracefold != null &&
      closebrackets != null &&
      foldcode != null &&
      foldgutter != null &&
      javascript != null &&
      matchbrackets != null; // shenanigans to get webpack to not remove the above imports

    this.editor = codemirror.fromTextArea(this.$refs.editor as HTMLTextAreaElement, {
      lineNumbers: true,
      mode: { name: 'javascript', json: true },
      readOnly: this.editorReadOnly,
      autoCloseBrackets: `${allPlugins}`,
      matchBrackets: allPlugins,
      foldGutter: true,
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
      value: this.value,
    });

    this.editor.on('change', this.textAreaSync);
    (this.$el.nextSibling as HTMLDivElement).style.height = `${this.height}px`;
  }

  private beforeDestroy() {
    this.editor.off('change', this.textAreaSync);
  }

  private destroy() {
    const element = this.editor.getDoc().getEditor().getWrapperElement();

    if (element && element.remove) {
      element.remove();
    }
  }

  private updateEditorFromValueProp() {
    if (this.editor.getValue() !== this.value) {
      const scrollInfo = this.editor.getScrollInfo();
      const left = scrollInfo.left;
      const top = scrollInfo.top;

      this.editor.setValue(this.value);
      this.editor.scrollTo(left, top);
    }
  }

  private textAreaSync(instance: CodeMirror.Editor) {
    this.$emit('input', instance.getValue());
  }

  @Watch('height')
  private watchHeight(newHeight: number, oldHeight: number) {
    (this.$el.nextSibling as HTMLDivElement).style.height = `${newHeight}px`;
  }
}
