




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { JsonPathingType } from '@/pathing/JsonPathingType';
import JsonPathing from '@/pathing/JsonPathing';

@Component
export default class Controls extends Vue {
  @Prop({ required: true }) public value!: JsonPathing;

  private transform() {
    this.$emit('transform');
  }

  get pathingType(): string {
    switch (this.value.pathingType) {
      case JsonPathingType.JSONATA:
        return 'JSONata';
      case JsonPathingType.PATHING:
        return 'JSONPath';
    }
  }

  set pathingType(pathType: string) {
    let pathToEmit = JsonPathingType.JSONATA;

    if (pathType === 'JSONPath') {
      pathToEmit = JsonPathingType.PATHING;
    }

    this.$emit('input', {
      pathingType: pathToEmit,
      path: this.value.path,
    });
  }

  get path(): string {
    return this.value.path;
  }

  set path(pathIn: string) {
    this.$emit('input', {
      pathingType: this.value.pathingType,
      path: pathIn,
    });
  }
}
