









































import { Component, Vue } from 'vue-property-decorator';
import JsonResultEditor from '@/editor/component/JsonResultEditor.vue';
import Controls from '@/pathing/component/Controls.vue';
import JsonPathingInput from '@/pathing/component/JsonPathingInput.vue';
import { JsonPathingType } from '@/pathing/JsonPathingType';
import JsonPathingState from '@/pathing/JsonPathingState';
import jsonpath from 'jsonpath';
import jsonata from 'jsonata';
import { search as jmespath } from 'jmespath';

@Component({
  components: {
    Controls,
    JsonPathingInput,
    JsonResultEditor,
  },
})
export default class Home extends Vue {
  private state: JsonPathingState = {
    input: {
      json: `{\n  "store": {\n    "book": [\n      {\n        "category": "reference",\n        "author": "Nigel Rees",\n        "title": "Sayings of the Century",\n        "price": 8.95\n      },\n      {\n        "category": "fiction",\n        "author": "Evelyn Waugh",\n        "title": "Sword of Honour",\n        "price": 12.99\n      },\n      {\n        "category": "fiction",\n        "author": "Herman Melville",\n        "title": "Moby Dick",\n        "isbn": "0-553-21311-3",\n        "price": 8.99\n      },\n      {\n        "category": "fiction",\n        "author": "J. R. R. Tolkien",\n        "title": "The Lord of the Rings",\n        "isbn": "0-395-19395-8",\n        "price": 22.99\n      }\n    ],\n    "bicycle": {\n      "color": "red",\n      "price": 19.95\n    }\n  }\n}`,
      pathing: {
        path: `store.book.author`,
        pathingType: JsonPathingType.JSONATA,
      },
    },
    result: `[\n  "Nigel Rees",\n  "Evelyn Waugh",\n  "Herman Melville",\n  "J. R. R. Tolkien"\n]`,
    maximized: 'none',
  };

  private formatInput() {
    this.state.input.json = this.formatJson(this.state.input.json);
  }

  private flattenInput() {
    this.state.input.json = this.flattenJson(this.state.input.json);
  }

  private normalizeInput() {
    this.state.maximized = 'none';
  }

  private maximizeInput() {
    this.state.maximized = 'input';
  }

  private transformInput() {
    let transformedJson;
    let originalJsonResult = this.state.result;

    try {
      const parsedInputJson = JSON.parse(this.state.input.json);
      const jsonPath = this.state.input.pathing.path.trim();

      switch (this.state.input.pathing.pathingType) {
        case JsonPathingType.JSONATA:
          transformedJson = jsonata(jsonPath).evaluate(parsedInputJson) || {};
          break;
        case JsonPathingType.PATHING:
          transformedJson = jsonpath.query(parsedInputJson, jsonPath) || {};
          break;
        case JsonPathingType.JMES:
          transformedJson = jmespath(parsedInputJson, jsonPath) || {};
          break;
        default:
          transformedJson = originalJsonResult;
          break;
      }

      transformedJson = JSON.stringify(transformedJson, null, 2);
    } catch (e) {
      this.$store.commit('reportError', { errorMessageHeader: 'JSON Expression Error', errorMessageBody: e.message });
      transformedJson = originalJsonResult;
    }

    this.state.result = transformedJson;
  }

  private formatResult() {
    this.state.result = this.formatJson(this.state.result);
  }

  private flattenResult() {
    this.state.result = this.flattenJson(this.state.result);
  }

  private normalizeResult() {
    this.state.maximized = 'none';
  }

  private maximizeResult() {
    this.state.maximized = 'result';
  }

  private formatJson(jsonIn: string): string {
    try {
      const parsedJson = JSON.parse(jsonIn);

      return JSON.stringify(parsedJson, null, 2);
    } catch (e) {
      this.$store.commit('reportError', { errorMessageHeader: 'JSON Format Error', errorMessageBody: e.message });
      return jsonIn;
    }
  }

  private flattenJson(jsonIn: string): string {
    try {
      const parsedJson = JSON.parse(jsonIn);

      return JSON.stringify(parsedJson);
    } catch (e) {
      this.$store.commit('reportError', { errorMessageHeader: 'JSON Flatten Error', errorMessageBody: e.message });
      return jsonIn;
    }
  }

  get usableHeight(): number {
    return this.$store.state.windowDimensions.height - 44;
  }
}
