


















import { Component, Prop, Vue } from 'vue-property-decorator';
import EditorControls from '@/editor/component/EditorControls.vue';
import JsonEditor from '@/editor/component/JsonEditor.vue';

@Component({
  components: {
    JsonEditor,
    EditorControls,
  },
})
export default class ResultEditor extends Vue {
  @Prop({ type: String, required: true }) public value!: string;
  @Prop({ type: Number, required: true }) public height!: number;
  @Prop({ type: Boolean, default: false }) public maximized!: boolean;

  private editorControls: HTMLElement | null = null;

  private mounted() {
    this.editorControls = this.$refs.editorControls as HTMLElement;
  }

  get workingHeight(): number {
    const editorControlsHeight = this.editorControls?.offsetHeight || 0;

    return this.height - editorControlsHeight;
  }
}
