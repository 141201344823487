


















import { Component, Prop, Vue } from 'vue-property-decorator';
import Controls from '@/pathing/component/Controls.vue';
import JsonEditor from '@/editor/component/JsonEditor.vue';
import EditorControls from '@/editor/component/EditorControls.vue';
import JsonInput from '@/pathing/JsonInput';
import JsonPathing from '@/pathing/JsonPathing';

@Component({
  components: {
    EditorControls,
    Controls,
    JsonEditor,
  },
})
export default class JsonPathingInput extends Vue {
  @Prop({ required: true }) value!: JsonInput;
  @Prop({ type: Number, required: true }) height!: number;
  @Prop({ type: Boolean, default: false }) public maximized!: boolean;

  private editorControls: HTMLElement | null = null;

  private mounted() {
    this.editorControls = this.$refs.editorControls as HTMLElement;
  }

  get workingHeight(): number {
    const editorControlsHeight = this.editorControls?.offsetHeight || 0;

    return this.height - editorControlsHeight;
  }

  get path(): JsonPathing {
    return this.value.pathing;
  }

  set path(pathing: JsonPathing) {
    this.$emit('input', {
      pathing,
      json: this.value.json,
    });
  }

  get json(): string {
    return this.value.json;
  }

  set json(json: string) {
    this.$emit('input', {
      pathing: this.value.pathing,
      json,
    });
  }
}
